<template>
  <section
    :class="[
      {
        'border-t border-t-gray-200': border,
        'py-25 lg:py-30 xl:py-40': padding,
      },
      bgClass,
    ]"
  >
    <Container>
      <component
        :is="heroTitle ? 'div' : 'h2'"
        v-if="title"
        v-blokkli-editable="{ name: editableTitle }"
        class="pre-heading sticky top-sticky-top mobile-only:px-15 mobile-only:-mx-15 z-40 py-15 transition-all duration-250 ease-in-out -mt-15 backdrop-blur-md mb-20"
        :class="[textColor, bgClassSticky]"
      >
        {{ title }}
      </component>
      <header v-if="renderHeader" class="mb-10 lg:mb-20 xl:mb-30">
        <slot name="header">
          <h2
            v-if="heroTitle"
            v-blokkli-editable="{ name: editableHeroTitle }"
            class="h2 break-words hyphens-auto"
            :class="textColor"
          >
            {{ heroTitle }}
          </h2>
          <p
            v-if="lead"
            v-blokkli-editable="{ name: editableLead }"
            :class="textColor"
            class="mt-20 lg:text-lg xl:w-4/6 break-words hyphens-auto"
          >
            {{ lead }}
          </p>
        </slot>
      </header>

      <div>
        <slot />
      </div>
    </Container>
  </section>
</template>

<script lang="ts" setup>
const slots = useSlots()

const props = defineProps<{
  title?: string
  heroTitle?: string
  lead?: string
  padding?: boolean
  border?: boolean
  bgLightGreen?: boolean
  bgGray?: boolean
  bgBlue?: boolean
  bgPrimary?: boolean
  bgPrimaryLight?: boolean
  bgWhite?: boolean

  editableTitle?: string
  editableHeroTitle?: string
  editableLead?: string
}>()

const renderHeader = computed(
  () => !!(slots.header || props.heroTitle || props.lead),
)

const textColor = computed(() => {
  if (props.bgPrimary || props.bgBlue) {
    return 'text-white'
  } else if (props.bgPrimaryLight) {
    return 'text-primary-600'
  }
  return 'text-primary-700'
})

const bgClass = computed(() => {
  if (props.bgGray) {
    return 'bg-gray-20'
  } else if (props.bgBlue) {
    return 'bg-blue-900'
  } else if (props.bgPrimary) {
    return 'bg-primary-500'
  } else if (props.bgPrimaryLight) {
    return 'bg-primary-100'
  }

  return 'bg-white'
})

const bgClassSticky = computed(() => {
  if (props.bgGray) {
    return 'bg-gray-20/80'
  } else if (props.bgBlue) {
    return 'bg-blue-900/80'
  } else if (props.bgPrimary) {
    return 'bg-primary-500/80'
  } else if (props.bgPrimaryLight) {
    return 'bg-primary-100/80'
  }

  return 'bg-white/80'
})

defineOptions({
  name: 'LayoutSection',
})
</script>
